import * as React from "react"
import {indexHolder} from './Pages.module.scss'

// markup
const IndexPage = () => {
  return (
    <article className={indexHolder}>
    </article>
  )
}

export default IndexPage
